import Api from '@/axios'

export default {
  getAllRoc (pageNumber, memberId = '') {
    return Api().get(`memberships/rocs?page=${pageNumber}&member_id=${memberId}`)
  },
  changeRocStatus (type, payload) {
    return Api().post(`memberships/rocs/status/${type}`, payload)
  }
}
