<template>
  <b-container fluid>
    <b-modal id="rocData" size="lg"  class="p-0" :title="'History'" hide-footer no-close-on-backdrop>
      <roc-entry :rocData="rocDataSteps" @sendWrongEntry="sendRoc"/>
    </b-modal>
    <!--Start Content Page -->
    <b-row>
      <b-col lg="12">
        <iq-card :class="{'p-0': memberId}">
          <template v-if="!memberId" v-slot:headerTitle>
            <h4 class="card-title">ROC</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <spinner-loading text = 'Loading' />
            </div>
            <b-table v-else striped responsive  :fields="columns" :items="rocList" class="mb-0 table-borderless" ref="selectableTable" sort-icon-left primary-key="phone"  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(status)="data">
                <i :class="['las la-check-circle font-size-22 ml-2',
                data.item.first_status=='pending'?'text-gray' : data.item.first_status=='approved'?'text-success':
                'text-danger']"></i>
                <i  :class="['las la-check-circle font-size-22 ml-2',data.item.second_status=='pending'? 'text-gray' :
                 data.item.second_status=='approved'?'text-success':
                'text-danger']"></i>
                <i  :class="['las la-check-circle font-size-22 ml-2',data.item.third_status=='pending'? 'text-gray' :
                 data.item.third_status=='approved'?'text-success':
                'text-danger']"></i>
              </template>
              <template v-slot:cell(name)="data1">
                <router-link :to="{name:'profile', params:{id :data1.item.member_package.member.id}}">
                  <h5><b-badge variant="primary">{{data1.item.member_package.member.name}}</b-badge></h5>
                </router-link>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showRoc(data.item)">
                  <i class="las la-eye"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination v-model="pagination.current_page"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getAllRocs"
            >

            </b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!--End Content Page -->
  </b-container>
</template>
<script>
import rocService from '../services/roc'
import rocEntry from '../components/roc'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      wrongEntryDataSteps: null,
      loadTable: false,
      transProps: {
        name: 'flip-list'
      },
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 6
      },
      rocList: [],
      rocDataSteps: {},
      columns: [
        { label: 'Member Name', key: 'name', class: 'text-left' },
        { label: 'User', key: 'name', class: 'text-left' },
        { label: 'Package', key: 'member_package.package_title', class: 'text-left' },
        { label: 'Reason', key: 'reason.reason', class: 'text-left' },
        { label: 'Reason', key: 'reason.group', class: 'text-left' },
        { label: 'Old Value', key: 'old_value', class: 'text-left' },
        { label: 'New Value', key: 'value', class: 'text-left' },
        { label: 'Status', key: 'status', class: 'text-left' },
        { label: 'Actions', key: 'action', class: 'text-left' }
      ]
    }
  },
  components: {
    rocEntry
  },
  props: {
    memberId: {
      require: false
    }
  },
  methods: {
    getAllRocs () {
      this.loadTable = true
      rocService.getAllRoc(this.pagination.current_page, this.memberId).then(res => {
        this.rocList = res.data.data
        this.pagination.current_page = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
        this.loadTable = false
      })
    },
    showRoc (data) {
      this.rocDataSteps = data
      this.$bvModal.show('rocData')
    },
    sendRoc (payload) {
      rocService.changeRocStatus(payload.type, payload).then((res) => {
        this.getAllRocs()
        core.showSnackbar('success', res.data.message)
      }).finally(() => {
        this.$bvModal.hide('rocData')
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllRocs()
  }
}
</script>
<style>
.p-0 {
  padding: 0 !important;
}
</style>
